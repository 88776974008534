import Vue from 'vue';
import {
  Message,
  Notification,
  Container,
  Aside,
  Main,
  Header,
  Carousel,
  CarouselItem,
  Input,
  Rate,
  Dialog,
  Button,
  RadioGroup,
  Radio,
  Checkbox,
  CheckboxGroup,
  CheckboxButton,
  Upload,
  Select,
  Option,
  Pagination,
  Menu,
  MenuItem,
  MenuItemGroup,
  Submenu,
  DatePicker,
  Table,
  TableColumn,
  Switch,
  Row,
  Col,
  Form,
  FormItem,
  Alert,
  Steps,
  Step,
  Calendar,
  Tooltip,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Cascader,
  Popconfirm,
  MessageBox,
  Image,
  Loading,
  Drawer,
  InfiniteScroll
} from 'element-ui';

Vue.prototype.$message = Message;
Vue.prototype.$notification = Notification;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$alert = MessageBox.alert;
Vue.use(Container)
  .use(Aside)
  .use(Main)
  .use(Header)
  .use(Carousel)
  .use(Input)
  .use(Rate)
  .use(Dialog)
  .use(Button)
  .use(RadioGroup)
  .use(Checkbox)
  .use(Radio)
  .use(CheckboxGroup)
  .use(CheckboxButton)
  .use(Upload)
  .use(Pagination)
  .use(Select)
  .use(Option)
  .use(Menu)
  .use(MenuItem)
  .use(MenuItemGroup)
  .use(Submenu)
  .use(DatePicker)
  .use(Table)
  .use(TableColumn)
  .use(Switch)
  .use(Row)
  .use(Col)
  .use(Form)
  .use(FormItem)
  .use(Alert)
  .use(Steps)
  .use(Step)
  .use(Calendar)
  .use(Tooltip)
  .use(Dropdown)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(Cascader)
  .use(Popconfirm)
  .use(Image)
  .use(Loading)
  .use(Loading.directive)
  .use(InfiniteScroll)
  // .use(InfiniteScroll.directive)
  .use(Drawer)
  .use(CarouselItem);
