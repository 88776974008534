<template>
  <div class="login"></div>
</template>

<script>
import { token } from 'services/login';
import { userInfo, certificationStatus } from 'services/broker';
export default {
  name: 'Login',
  data () {
    return {
    };
  },
  async mounted () {
    console.log('-------', this.$route, location, decodeURIComponent(location.hash));
    // let hash = decodeURIComponent(location.hash)
    // 判断是否是从厂房进入发布房源的
    this.$nextTick(async () => {
      if (location.hash.includes('client=plant')) {
        console.log('1-------', location.hash);
        // 判断认证状态
        const res = await certificationStatus()
        console.log('判断认证状态', res);
        // if (res.data.status === 200)
        if (res.data.data.status !== 3) {
          this.$router.push('/user')
        } else {
          this.$router.push('/publishHousing')
        }
      } else {
        this.getToken();
      }

    })
  },
  methods: {
    async getToken () {
      const hash = decodeURIComponent(window.location.hash)
      let ticket = hash.split('ticket=')[1]
      let url = hash.split('from=#')[1].split('&')[0]
      let res = await token({ client: 'web', ticket });
      // 储存token
      localStorage.token = res.data.data.token;
      console.log('-------', this.$route, location, url);

      // 获取用户信息
      userInfo().then(res => {
        if (res.data.status === 200) {
          localStorage.userInfo = JSON.stringify(res.data.data)
        }
      })

      if (hash.includes('client=plant')) {
        console.log('1-------', location.hash);
        // 判断认证状态
        const res = await certificationStatus()
        console.log('判断认证状态', res);
        if (res.data.status === 200)
          if (res.data.data.status !== 3) {
            this.$router.push('/user')
          } else {
            this.$router.push('/publishHousing')
          }
      } else if (url.includes('/login')) {
        this.$router.push('/');
      } else {
        // 跳转到首页
        this.$router.push(url);
      }
    }
  }
};
</script>
