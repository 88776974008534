<template>
  <router-view id="app" />
</template>

<script>
import { isLogin, userInfo } from 'services/login';
import "./units/rem.js";
export default {
  name: 'app',
  mounted () {
    this.$nextTick(() => {
      var timer = setTimeout(() => {
        this.judgingLogin()
        clearTimeout(timer)
      }, 500)
    })
  },
  beforeRouteEnter (to, from, next) {
    console.log(to, from);
    next()
  },
  created () {
    console.log('app-created', window.location.hash);
    isLogin().then(res => {
      if (res.data.status === 200) {
        console.log('app-created', window.location.hash);
        let hash = window.location.hash === '#/' ? '#/index' : window.location.hash
        if (!res.data.data) {
          window.location.href = window.loginIp + "/#/ticket?from=" + hash + '&jl';
        }
      }
    })
  },
  methods: {
    judgingLogin () {
      isLogin().then(res => {
        if (res.data.status === 200) {
          if (!res.data.data) {
            localStorage.removeItem('userInfo')
          } else {
            // 获取用户信息
            userInfo().then(r => {
              if (r.data.status === 200) {
                localStorage.userInfo = JSON.stringify(r.data.data)

                this.$eventBus.$emit('getUserInfo', r.data.data)
              }
            })
          }
        }
      })
    },
  }
};
</script>

<style>
#app {
  height: 100%;
  background: #faf8f5;
}
</style>
