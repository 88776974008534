import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './plugins/axios';
import './plugins/element.js';
import './styles/reset.less';
import './styles/element.less';
import 'element-ui/lib/theme-chalk/index.css';
import './styles/icon.less';
// import './styles/font.less';
import * as filters from './filters';
import { message } from './units/resetMessage.js';

Vue.config.productionTip = false;
Vue.prototype.$eventBus = new Vue();
Vue.prototype.$message = message;

// 登录状态
Vue.prototype.$LOGINSTATUS = false;

// 全局过滤
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]); // 插入过滤器名和对应方法
});

// 地图引入
// new Promise(function (resolve, reject) {
// window.onload = function () {
//   // eslint-disable-next-line
//   window.BMapGL = BMapGL;
// };
// // 如果已加载直接返回
// if (typeof BMapGL !== 'undefined') {
//   // eslint-disable-next-line
//   window.BMapGL = BMapGL;
// }
// // 百度地图异步加载回调处理
// window.onBMapCallback = function () {
//   // eslint-disable-next-line
//   window.BMapGL = BMapGL;
//   console.log('onBMapCallback', window.BMapGL);
// };
// // const ak = 'rAHTYQTI88gCQXsym3xDmdBeg6PShkOw';
// // const ak = '0G6AY6KiQjnfzWGRMPGCEKkVVrosknjp';
// const ak = '26z9D0KsGMqPXjNW8vVV3Kfa4zkS2BDg';
// const script = document.createElement('script');
// script.type = 'text/javascript';
// script.src = `https://api.map.baidu.com/api?v=2.0&type=webgl&ak=${ak}&callback=onBMapCallback&s=1`;
// // script.onerror = reject;
// document.head.appendChild(script);
// });

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
