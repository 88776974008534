import axios from '../plugins/broker';

import qs from 'qs';

// form 表单传参
const form = {
  transformRequest: [
    function (data) {
      data = qs.stringify(data);
      return data;
    }
  ],
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
};

// 获取用户信息
const userInfo = async () => axios.get('/user/get');

// 查询经纪人/业主信息
const getOpenUser = async data => axios.post('/open/user/get', data, form);

// 获取认证状态
const certificationStatus = () => axios.get('/certification/status');

// 提交业主认证
const ownerSubmit = async data =>
  axios.post('/certification/owner/submit', data, form);

// 提交经纪人认证
const brokerSubmit = async data =>
  axios.post('/certification/broker/submit', data, form);

// 取消认证
const certificationCancel = async data =>
  axios.post('/certification/cancel', data, form);

// 查询最新的业主认证信息
const ownerLast = () => axios.get('/certification/owner/last');

// 查询最新的经纪人认证信息
const brokerLast = () => axios.get('/certification/broker/last');

// 发布房源-厂房
const publishBuild = async data =>
  axios.post('/building/factory/publish', data, form);

// 发布房源-楼盘
const addOfficeBuilding = async data =>
  axios.post('/building/office/add', data, form);

// 楼盘-快速搜索
const officeSearchQuick = async params =>
  axios.get('/building/office/search/quick', { params });

// 发布房源-写字楼出租
const officeLeasePublish = async data =>
  axios.post('/office/lease/publish', data, form);

// 分页查询我的房源-厂房出租
const buildPage = async params =>
  axios.get('/building/factory/page', { params });

// 分页查询我的房源-写字楼出租
const officeLeasePage = async params =>
  axios.get('/office/lease/page', { params });

// 修改房源状态-厂房出租
const changeBuildStatus = async data =>
  axios.post('/building/factory/status/change', data, form);

// 修改房源状态-写字楼出租
const changeOfficeStatus = async data =>
  axios.post('/office/lease/status/change', data, form);

// 房源转录-根据注册手机号查询认证信息
const getByPhone = async params => axios.get('/user/getByPhone', { params });

// 房源转录-转录房源
const propertyTransfer = async data =>
  axios.post('/building/factory/property/transfer', data, form);

// 房源转录-转录房源
const officePropertyTransfer = async data =>
  axios.post('/office/lease/property/transfer', data, form);

// 房源详情-厂房出租
const buildDetail = async params =>
  axios.get('/building/factory/detail', { params });

// 房源详情-写字楼出租
const officeLeaseDetail = async params =>
  axios.get('/office/lease/detail', { params });

// 编辑厂房出租房源
const editPublishBuild = async data =>
  axios.post('/building/factory/edit', data, form);

// 写字楼出租-编辑房源
const editOfficeLease = async data =>
  axios.post('/office/lease/edit', data, form);

// 获取房源审核信息-厂房出租
const buildingFactoryReviewRecord = async params =>
  axios.get('/building/factory/review/record', { params });

// 获取房源审核信息-写字楼出租
const getOfficeReviewRecord = async params =>
  axios.get('/office/review/record/get', { params });

// 厂房委托-分页查询
const entrustFactoryPage = async params =>
  axios.get('/entrust/factory/page', { params });

// 厂房委托-处理委托
const entrustFactoryHandle = async data =>
  axios.post('/entrust/factory/handle', data, form);

// 厂房委托-删除
const entrustFactoryDel = async data =>
  axios.post('/entrust/factory/del', data, form);

// 客户列表-查询日新增客户
const customCountDay = async params =>
  axios.get('/custom/count/day', { params });

// 客户列表-分页查询
const customPage = async params => axios.get('/custom/page', { params });

// 客户列表-编辑
const customEdit = async data => axios.post('/custom/edit', data, form);

// 客户列表-删除客户接口
const customDel = async data => axios.post('/custom/del', data, form);

// 客户列表-添加带看
const customTakeView = async data => axios.post('/custom/takeView', data, form);

// 带看列表-分页查找
const takeViewPage = async params => axios.get('/takeView/page', { params });

// 带看列表-查询日带看量
const takeViewCount = async data => axios.post('/takeView/count', data, form);

// 带看列表-删除记录
const takeViewDel = async data => axios.post('/takeView/del', data, form);

// 带看列表-查询日带看量
const takeViewTake = async data => axios.post('/takeView/take', data, form);

// 带看列表-更改时间
const takeViewChangeTime = async data =>
  axios.post('/takeView/changeTime', data, form);

// 通话记录-分页查询
const callRecordPage = async params =>
  axios.get('/call/record/page', { params });

// 通话记录-编辑
const callRecordEdit = async data =>
  axios.post('/call/record/edit', data, form);

// 通话记录-删除
const callRecordDel = async data => axios.post('/call/record/del', data, form);

// 通话记录-获取未接通的号码
const callRecordMissed = async data =>
  axios.post('/call/record/missed/getPhone', data, form);

// 首页数据统计
const homePage = async params =>
  axios.get('/homePage/data/overview', { params });

// 首页带看-按日期区间查询每日总数
const homeTakeView = async params =>
  axios.get('/takeView/count/day/interval', { params });

// 个人信息-发送更改手机验证码
const changePhoneCode = async data =>
  axios.post('/user/phone/change/code/send', data, form);

// 个人信息-更改手机号码
const changePhone = async data => axios.post('/user/phone/change', data, form);

export {
  userInfo,
  getOpenUser,
  certificationStatus,
  ownerSubmit,
  brokerSubmit,
  certificationCancel,
  ownerLast,
  brokerLast,
  publishBuild,
  addOfficeBuilding,
  officeSearchQuick,
  officeLeasePublish,
  buildPage,
  officeLeasePage,
  changeBuildStatus,
  changeOfficeStatus,
  getByPhone,
  propertyTransfer,
  officePropertyTransfer,
  buildDetail,
  officeLeaseDetail,
  editPublishBuild,
  editOfficeLease,
  buildingFactoryReviewRecord,
  getOfficeReviewRecord,
  entrustFactoryPage,
  entrustFactoryHandle,
  entrustFactoryDel,
  customCountDay,
  customPage,
  customEdit,
  customDel,
  customTakeView,
  takeViewPage,
  takeViewCount,
  takeViewDel,
  takeViewTake,
  takeViewChangeTime,
  callRecordPage,
  callRecordEdit,
  callRecordDel,
  callRecordMissed,
  homePage,
  homeTakeView,
  changePhoneCode,
  changePhone
};
