import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    component: Login
  },
  {
    path: '',
    component: () => import(/* webpackChunkName: "home" */ 'views/Home.vue'),
    redirect: '/index',
    children: [
      {
        path: 'module',
        component: () => import(/* */ 'views/module/Module.vue')
      },
      {
        path: '/index',
        component: () => import(/* */ 'views/Index.vue')
      },
      // 委托找房
      {
        path: '/entrustLookHouse',
        name: 'entrustLookHouse',
        component: () =>
          import(
            /* webpackChunkName: "home" */ 'views/entrustLookHouse/EntrustLookHouse.vue'
          )
      },
      // 投诉记录
      {
        path: '/complainsRecords',
        name: 'complainsRecords',
        component: () =>
          import(
            /* webpackChunkName: "home" */ 'views/entrustLookHouse/ComplainsRecords.vue'
          )
      },
      {
        path: '/myHousing',
        name: 'myHousing',
        component: () =>
          import(/* webpackChunkName: "home" */ 'views/MyHousing.vue')
      },
      // 发布房源
      {
        path: '/publishHousingType',
        name: 'publishHousingType',
        component: () =>
          import(
            /* webpackChunkName: "home" */ 'views/publishHousing/PublishHousingType.vue'
          )
      },
      // 发布房源-厂房出租
      {
        path: '/publishHousing',
        name: 'publishHousing',
        component: () =>
          import(
            /* webpackChunkName: "home" */ 'views/publishHousing/PublishHousing.vue'
          )
      },
      // 发布房源-写字楼出租
      {
        path: '/publishHousingOffice',
        name: 'publishHousingOffice',
        component: () =>
          import(
            /* webpackChunkName: "home" */ 'views/publishHousing/PublishHousingOffice.vue'
          )
      },
      // 客户列表
      {
        path: '/customerList',
        name: 'customerList',
        component: () =>
          import(/* webpackChunkName: "home" */ 'views/CustomerList.vue')
      },
      // 带看记录
      {
        path: '/tapeRecord',
        name: 'tapeRecord',
        component: () =>
          import(/* webpackChunkName: "home" */ 'views/TapeRecord.vue')
      },
      // 沟通记录
      {
        path: '/recordOfCommun',
        name: 'recordOfCommun',
        component: () =>
          import(/* webpackChunkName: "home" */ 'views/RecordOfCommun.vue')
      },
      // 账单管理
      {
        path: '/bill',
        name: 'bill',
        component: () =>
          import(/* webpackChunkName: "home" */ 'views/bill/Index.vue')
      },
      // 提现记录
      {
        path: '/withdrawalRecord',
        name: 'withdrawalRecord',
        component: () =>
          import(
            /* webpackChunkName: "home" */ 'views/bill/WithdrawalRecord.vue'
          )
      },
      //龙币管理
      {
        path: '/gold',
        name: 'gold',
        component: () =>
          import(/* webpackChunkName: "home" */ 'views/gold/Index.vue')
      },
      // 龙币充值
      {
        path: '/recharge',
        name: 'recharge',
        component: () =>
          import(/* webpackChunkName: "home" */ 'views/gold/Recharge.vue')
      },
      // 发票管理
      {
        path: '/invoice',
        name: 'invoice',
        component: () =>
          import(/* webpackChunkName: "home" */ 'views/invoice/Index.vue')
      },
      // 开票历史
      {
        path: '/billingHistory',
        name: 'billingHistory',
        component: () =>
          import(
            /* webpackChunkName: "home" */ 'views/invoice/BillingHistory.vue'
          )
      },
      // 开票信息
      {
        path: '/billingInfo',
        name: 'billingInfo',
        component: () =>
          import(/* webpackChunkName: "home" */ 'views/invoice/BillingInfo.vue')
      },
      // 发票抬头管理
      {
        path: '/billingTitle',
        name: 'billingTitle',
        component: () =>
          import(
            /* webpackChunkName: "home" */ 'views/invoice/BillingTitle.vue'
          )
      },
      // 地址管理
      {
        path: '/billingAddress',
        name: 'billingAddress',
        component: () =>
          import(
            /* webpackChunkName: "home" */ 'views/invoice/BillingAddress.vue'
          )
      },
      // 基本消息
      {
        path: '/basicMessage',
        name: 'basicMessage',
        component: () =>
          import(/* webpackChunkName: "home" */ 'views/BasicMessage.vue')
      },
      // 基本信息
      {
        path: '/basicInfo',
        name: 'basicInfo',
        component: () =>
          import(/* webpackChunkName: "home" */ 'views/BasicInfo.vue')
      }
    ]
  },
  // 用户认证
  {
    path: '/user',
    name: 'user',
    component: () =>
      import(/* webpackChunkName: "home" */ 'views/user/Index.vue')
  },
  // 用户使用协议
  {
    path: '/userAgreement',
    name: 'userAgreement',
    component: () =>
      import(/* webpackChunkName: "home" */ 'views/user/UserAgreement.vue')
  },
  // 微信支付
  {
    path: '/weiXinPay',
    name: 'weiXinPay',
    component: () =>
      import(/* webpackChunkName: "home" */ 'views/pay/WeiXinPay.vue')
  },
  // 注册认证-
  {
    path: '/registration',
    name: 'registration',
    component: () =>
      import(
        /* webpackChunkName: "home" */ 'views/user/RegistrationCertification.vue'
      )
  }
];

const router = new VueRouter({
  routes
});

export default router;
